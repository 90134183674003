import { CurrencyCode } from '@wff/types';

export const getCurrencySymbol = (currency: string | undefined) => {
  if (currency === CurrencyCode.EUR) return '€';
  if (currency === CurrencyCode.GBP) return '£';
  return '';
};

export function formatPrice(
  price: number | string,
  currency: CurrencyCode | string = CurrencyCode.EUR
): string {
  const symbol = getCurrencySymbol(currency);
  const n = Number(price);
  if (isNaN(n)) {
    console.log('formatPrice: invalid price', price);
    return '';
  }

  const n00 = n.toFixed(2);
  const lastThree = n00.substring(n00.length - 3);

  let numString = n00;
  if (lastThree === '.00') {
    // convert x.00 to x
    numString = n00.substring(0, n00.length - 3);
  }
  const formattedPrice = `${symbol}${numString}`;
  return formattedPrice;
}
